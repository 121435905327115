.section3 {
  padding: 80px 150px;
  text-align: center;
}

.section3 .h1 {
  text-align: center;
  max-width: 700px;
  line-height: 45px;
  display: inline-block;
  margin-bottom: 15px;
}

.section3 .h5 {
  margin-bottom: 30px;
}

.section3 .body {
  text-align: center;
  padding: 10px 0 30px 0;
}

.section3 .product {
  display: flex;
  columns: auto;
  height: 370px;
  border-radius: 29px;
  border: none;
  background-color: none;
  box-shadow: 4px 4px 32px 0px #0000001F;
}

.section3 .product:hover {
  box-shadow: 6px 6px 0px 0px #000000;
  transition: .3s ease-in-out;
}

.section3 .product .ant-card-body {
  display: flex;
  align-items: center;
}


@media (max-width: 768px) {
  .section3 {
    padding: 50px 30px 30px 30px;
  }
  .section3 .h1 { 
    text-align: left;
  }
  .section3 .h5 {
    text-align: justify;
  }
  .section3 .item-container {
    overflow-x: scroll;
    overflow-y: visible;
    flex-flow: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section3 .item-container::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}