.section1 {
  padding: 150px 150px;
}

.section1 a {
  padding-top: 20px;
  display: inline-block;
}

.section1 .img-section {
  height: 368px;
  width: 262px;
  border-radius: 400px;
  overflow: hidden;
}

.section1 .img-section img {
  box-shadow: 4px 4px 32px 0px #0000001F;
  transition: transform .5s ease;
}

.section1 .img-section:hover img {
  transform: scale(1.2);
}


@media (max-width: 768px) {
  .section1 {
    padding: 50px 30px;
  }
}
