.section4 {
  padding: 20px 150px;
  min-height: 300px;
  color: #FFFFFF;
  background: linear-gradient(262.3deg, #1979F5 0%, #48A4F7 50.19%, #BCDFFF 99.34%);
  overflow: hidden;
  position: relative;
}

.section4 .ant-space {
  width: 100%;
  height: 100%;
}

.section4 .image-animation .background {
  position: absolute;
  top: -40vw;
  right: -60vw;
  transition: .2s ease;
}
.section4:hover .image-animation .background {
  right: -55vw;
}
.section4 .image-animation .apps {
  position: absolute;
  top: -400px;
  right: 5vw;
  transition: .2s ease;
  scale: .5 .5;
}
.section4:hover .image-animation .apps {
  top: -520px;
}

.section4 .text-wrapper {
  width: 100%;
}
.section4 .text-wrapper .ant-space {
  margin: 10px 0;
}
.section4 .text-wrapper .ant-typography {
  color: #FFFFFF;
}

.section4 .text-wrapper .button-header {
  background-color: #000000;
  height: 36px;
  width: 261px;
  border-radius: 36px;
  padding: 0 22px;
  margin: 10px 0;
}

.section4 .text-wrapper .button-header:hover::after, .section4 .text-wrapper .button-header:focus::after {
  right: 50px;
}

.section4 .wrapper > .ant-space-item {
  z-index: 9;
}


@media (max-width: 768px) {
  .section4 {
    padding: 20px 30px;
    min-height: 720px;
  }

  .section4 .image-animation .background {
    bottom: 0;
    left: 30vw;
    right: unset;
    top: unset;
  }

  .section4 .image-animation .apps {
    top: -20vw;
    left: -30vw;
  }

  .section4:hover .image-animation .background {
    left: 30vw;
    right: unset;
  }

  .section4:hover .image-animation .apps {
    top: -20vw;
  }
}