.section6 {
  padding: 20px 150px;
}

.section6 .h1 {
  max-width: 700px;
  line-height: 45px;
  display: inline-block;
  margin-bottom: 15px;
}

.section6 .h5 {
  margin-bottom: 10px;
  text-align: left;
}

.section6 .product {
  display: flex;
  padding: 0 70px;
  box-shadow: 4px 4px 32px 0px #0000001F;
  border: none;
  height: 400px;
  border-radius: 29px;
  align-items: center;
  justify-content: space-around;
}

.section6 .product .left {
  position: relative;
  z-index: 10;
}

.section6 .image-animation {
  height: 100%;
  width: 100%;
  position: relative;
  /* overflow: hidden; */
}

.section6 .image-animation img {
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto;
  transition: .2s ease;
  cursor: pointer;
}

.section6 .image-animation img:nth-child(1) {
  right: 315px;
  z-index: 5;
}
.section6 .image-animation img:nth-child(2) {
  right: 210px;
  z-index: 4;
}
.section6 .image-animation img:nth-child(3) {
  right: 105px;
  z-index: 3;
}
.section6 .image-animation img:nth-child(4) {
  right: 0;
  z-index: 2;
}

.section6 .image-animation img:nth-child(1):hover {
  scale: 1.2 1.2;
  z-index: 5;
}
.section6 .image-animation img:nth-child(2):hover {
  scale: 1.2 1.2;
  z-index: 5;
}
.section6 .image-animation img:nth-child(3):hover {
  scale: 1.2 1.2;
  z-index: 5;
}
.section6 .image-animation img:nth-child(4):hover {
  scale: 1.2 1.2;
  z-index: 5;
}

/* .section6:hover .image-animation .background {
  right: -55vw;
} */


@media (max-width: 768px) {
  .section6 {
    padding: 20px 30px;
  }

  .section6 .product {
    padding: 30px 20px;
    align-items: flex-start;
  }

  .section6 .product .ant-card-body {
    padding: 0;
  }

  .section6 .image-animation {
    height: 200px;
    scale: .6 .6;
  }

  .section6 .image-animation img:nth-child(1) {
    right: 255px;
    z-index: 5;
  }
  .section6 .image-animation img:nth-child(2) {
    right: 150px;
    z-index: 4;
  }
  .section6 .image-animation img:nth-child(3) {
    right: 45px;
    z-index: 3;
  }
  .section6 .image-animation img:nth-child(4) {
    right: -60px;
    z-index: 2;
  }
}