.faq-section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  padding: 40px 16px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f1f4f7;
}

.faq-section .faq-action {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-flow: column nowrap;
}

.faq-section .faq-title {
  font-family: "Source Sans 3";
  font-size: 24px;
  font-weight: 600;
  line-height: 34.18px;
  text-align: left;
  margin: 0;
}

.faq-section .faq-text {
  font-family: "Source Sans 3";
  font-size: 18px;
  font-weight: 400;
  line-height: 25.63px;
  text-align: left;
  color: #757575;
  margin-top: -16px;
}

.faq-section .faq-button {
  display: block;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  gap: 10px;
  border-radius: 10px;

  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #007aff;
  border: none;
}

.faq-section .faq-content {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 80px;
}

.faq-section .faq-collapse {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  gap: 16px;
  background-color: transparent;
}

.faq-section .faq-collapse-panel {
  width: 100%;
  background-color: #fff;
  border-radius: 16px !important;
}

.faq-collapse-panel .ant-collapse-header {
  padding: 16px 12px 16px 24px !important;
  display: flex !important;
  gap: 8px !important;
  flex-wrap: nowrap !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  font-family: "Source Sans 3";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.019em;
  text-align: left;
}

.faq-collapse-panel .ant-collapse-header .ant-collapse-header-text {
  width: calc(100% - 8px - 24px);
}

.faq-collapse-panel .ant-collapse-header .ant-collapse-expand-icon {
  height: 24px;
  width: 24px;
}

.faq-collapse-panel
  .ant-collapse-header
  .ant-collapse-expand-icon
  .ant-collapse-arrow {
  width: 100% !important;
  display: block !important;
  height: auto;
  position: relative !important;
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
.faq-collapse-panel .ant-collapse-content {
  padding: 0 16px 24px !important;
}

.faq-collapse-panel .ant-collapse-content .ant-collapse-content-box {
  font-size: 18px !important;
  font-weight: 400;
  color: #757575;
  line-height: 1.5;
  padding: 0;
}

.faq-collapse-panel .ant-collapse-content .ant-collapse-content-box p {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 769px) {
  .faq-section {
    display: flex;
    padding: 40px 16px;
  }
}

@media screen and (min-width: 849px) {
  .faq-section {
    padding: 40px 40px;
    flex-direction: row;
  }

  .faq-section .faq-action {
    width: calc(40% - 16px);
  }

  .faq-section .faq-content {
    width: calc(60% - 16px);
  }
}

@media screen and (min-width: 992px) {
  .faq-section {
    padding: 80px 40px;
  }
}

@media screen and (min-width: 1280px) {
  .faq-section {
    gap: 72px;
  }

  .faq-section .faq-action {
    width: calc(30% - 36px);
  }

  .faq-section .faq-content {
    width: calc(70% - 36px);
  }
}
