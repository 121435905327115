.social-media {
  width: 100%;
  justify-content: center;
  color: #000;
  height: 80px;
}

.social-media a {
  color: #000;
  display: flex;
  align-items: center;
}

.social-media a > span:last-child {
  display: inline;
}

.social-media a > span:last-child::after {
  transition: opacity .2s;
  background: none repeat scroll 0 0 #000000;
  content: "";
  display: block;
  height: 1px;
  opacity: 0;
}
.social-media a:hover > span:last-child::after {
  opacity: 1;
}

@media (max-width: 768px) {
  .social-media a {
    display: block;
    text-align: center;
    margin-bottom: 1.5rem;
  }
}