#root {
  /* background-color: black;
  color: white; */
  /* font-size: 14px; */
  /* font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; */
  /* letter-spacing: 0.5px; */
}

.page table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  font-weight: bold;
}
.page table tbody {
  color: black;
}

.page thead tr {
  border-bottom: 2px solid white;
}

.page td, .page th {
  vertical-align: bottom;
  padding: 8px;
  text-align: center;
}

.disclaimer {
  font-style: italic;
  position: fixed;
  bottom: 0;
  margin: 0;
  padding: 0;
  font-size: 8px;
  line-height: 8px;
}

.page tbody tr:nth-child(even) {
  background-color: grey;
}

.page tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}