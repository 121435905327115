.features-slider {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  background-color: #ffffff;
  border: 1px solid #ededed;
  border-radius: 16px;
  box-shadow: 0px 14px 50px 0px #0000000a;
  overflow: hidden;
}

.features-slider .slider-image-section {
  width: 100%;
  padding: 40px 20px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.features-slider .slider-content-section {
  width: 100%;
  display: flex;
  padding: 32px 16px;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 8px;
}

.features-slider .slider-image {
  display: flex;
  align-items: center;
  align-items: flex-end;
  height: 100%;
  width: fit-content;
  max-width: 100%;
}

.features-slider .slider-content-section .ant-image {
  width: 52px;
  height: 52px;
}

.features-slider .slider-icon {
  width: 52px;
  height: 52px;
}

.features-slider .slider-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 47px;
  text-align: left;
  margin: 0;
}

.features-slider .slider-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.63px;
  text-align: left !important;
  display: block;
}

.features-slider .slider-content-section .swiper {
  width: 100%;
}

.features-slider .slider-content-section .swiper .swiper-wrapper {
  height: auto;
}

.features-slider .slider-content-section .swiper .swiper-pagination {
  justify-content: flex-start;
  margin-top: 24px;
}

@media screen and (min-width: 992px) {
  .features-slider {
    flex-direction: row;
  }

  .features-slider .slider-image-section {
    width: 50%;
  }

  .features-slider .slider-content-section {
    width: 50%;
  }
}
