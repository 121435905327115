@media only screen and (min-width: 992px) {
  .nav-header {
    margin: 0 150px;
  }
}

.sfs-layout .sfs-menu {
  border-top: 1px solid #e3e7ef;
  display: none;
  overflow-y: auto !important;
  height: 550px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: #ffffff;
  opacity: 0;
  transition: 0.3s;
  border-radius: 20px;
}
.sfs-layout .sfs-menu.active {
  display: flex;
  opacity: 1;
  z-index: 999;
  box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px #00000007;

  box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px #0000000b;

  box-shadow: 0px 100px 80px 0px #00000012;
}
.menu-header-title {
  font-weight: 600;
  font-size: 18px;
  display: block;
  color: #677489;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.menu-header-title::before {
  content: "";
  margin-left: 5px;
  margin-right: 10px;
  border-left: thick solid #1979f5;
  border-radius: 25px;
}
.menu-header-title > a {
  width: 100%;
  display: block;
}
.menu-container a > .ant-space {
  width: 100%;
}

.menu-title-group {
  display: flex;
  align-items: center;
}

.menu-title-group .ant-image {
  padding-right: 10px;
}

.menu-title {
  display: block;
}
.menu-title,
.language-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #111b29;
}
.sfs-menu .border-right {
  border-right: 1px solid #e5e5ea;
}

.sfs-menu .menu-container .ant-space-item {
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px 3px;
  border-radius: 8px;
  min-width: 270px;
}
.sfs-menu .menu-container .ant-space-item:hover {
  background-color: #f1f1f1;
}
.menu-sub-title,
.menu-sub-title-2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #677489;
  position: relative;
}

.menu-sub-title-2::before {
  font: var(--fa-font-solid);
  content: "\f061";
  padding-right: 10px;
  color: #111b29;
  display: inline-block;
  position: relative;
  transform: translateX(0%);
}

.menu-sub-title-2:hover::before {
  transform: translateX(20%);
  transition: 0.3s ease-in-out;
}

/* .sfs-menu a .menu-title::after {
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0 #000000;
  content: "";
  display: block;
  height: 1px;
  width: 0;
}
.sfs-menu a:hover .menu-title::after {
  width: 90%;
} */

.img-tutorial > .ant-image {
  background: #000000;
  border-radius: 15px;
  border: none;
}

.img-tutorial > .ant-image img {
  opacity: 0.9;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.img-tutorial > .ant-image:hover img {
  opacity: 1;
  cursor: pointer;
}

.sfs-layout .sfs-menu .ant-switch {
  background-color: #f1f4f7 !important;
}
.sfs-layout .sfs-menu .ant-switch-handle::before {
  background-color: transparent;
  box-shadow: none;
}
.sfs-layout .sfs-menu .ant-switch-inner {
  margin: 0 0 0 25px;
}
.sfs-layout .sfs-menu .ant-switch-checked .ant-switch-inner {
  margin: 0 20px 0 0;
}
.sfs-layout .sfs-menu .language-title {
  margin-left: 10px;
}
.modal-youtube .ant-modal-body {
  padding: 0;
}
.modal-youtube .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.sfs-menu .menu-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sfs-menu .menu-footer .social-media {
  height: auto;
}

.sfs-menu .menu-footer > div:first-child {
  width: 100%;
}

@media (max-width: 768px) {
  .sfs-layout .sfs-menu {
    padding: 45px 20px;
  }
}
