.section2 {
  padding: 50px 150px;
  background: linear-gradient(180deg, #48A4F7 0%, #BCDFFF 41.67%, #FFFFFF 100%);
  text-align: center;
}

.section2 a {
  padding-top: 20px;
  display: inline-block;
}

.section2 .ant-typography.h1 {
  text-align: center;
}

.section2 .img-section {
  text-align: right;
}
.section2 .img-section .ant-image {
  height: 270px;
  max-width: 452px;
  overflow: hidden;
  margin-left: auto;
  border-radius: 112px 8px 112px 112px;
}
.section2 .img-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform .5s ease;
}
.section2 .img-section:hover img {
  transform: scale(1.2);
}

.section2 .carousel-product {
  padding: 50px 0;
  display: block;
}
.section2 .carousel-product .ant-carousel, .section2 .carousel-product .ant-carousel .slick-slider {
  height: 100%;
}
.section2 .carousel-product .ant-card {
  border-radius: 29px;
  box-shadow: 4px 4px 32px 0px #0000001F;
}
.section2 .carousel-product .ant-card .ant-card-body {
  height: 100%;
  padding: 26px 80px;
}

.section2 .carousel-product .slick-dots li {
  height: 8px;
  width: 8px;
  bottom: -120px;
}

.section2 .carousel-product .slick-dots li button {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #BCDFFF;
}

.section2 .carousel-product .slick-dots li.slick-active button {
  background-color: #000000;
}

.section2 .carousel-product .ant-row {
  display: flex !important;
}

.section2 .button-group {
  margin-top: 20px;
  width: 100%;
  overflow-y: auto;
}

.section2 .button-group::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

@media (max-width: 768px) {
  .section2 {
    padding: 50px 30px 15px 30px;
  }

  .section2 .carousel-product .ant-card .ant-card-body {
    padding: 26px 15px;
  }

  .section2 .img-section {
    text-align: center;
  }

  .section2 .carousel-product .slick-dots li {
    bottom: -100px;
  }
}
