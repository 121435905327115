.reksadana-card {
  width: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 14px 50px 0px #0000000a;
  overflow: hidden;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
}

.reksadana-card .rd-section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 18px;
}

.reksadana-card .rd-section.image-section {
  justify-content: center;
}

.reksadana-card .rd-section.detail-section {
  padding: 34px 24px;
}

.reksadana-card .rd-section .img-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  aspect-ratio: 1.53/1;
  max-height: 100%;
  justify-content: center;
  overflow: hidden;
  background-color: #d9d9d9;
}

.reksadana-card .rd-section .img-wrapper .ant-image {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reksadana-card .rd-section .img-wrapper img {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.reksadana-card .icon {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
}

.reksadana-card .icon .ant-image,
.reksadana-card .icon img {
  width: 100%;
  margin: 0 auto;
}

.reksadana-card .title-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
  flex-wrap: nowrap;
}

.reksadana-card .title {
  width: calc(100% - 48px - 16px);
  margin: 0;
  font-family: "Source Sans 3";
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #000000;
}

.reksadana-card .text {
  display: block;
  font-family: "Source Sans 3";
  font-size: 16px;
  font-weight: 400;
  line-height: 22.78px;
  text-align: justify;
  text-justify: inter-word;
  color: #4f5e71;
}

.reksadana-slider {
  width: 100%;
}

.reksadana-slider .swiper .swiper-slide {
  height: unset;
}

.reksadana-slider .swiper .swiper-pagination {
  margin-top: 32px;
}

.rd-container {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-flow: column nowrap;
  gap: 18px;
}

.rd-container .slider-button-container {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 10px;
  gap: 16px !important;
  scroll-behavior: smooth;
}

.rd-container .slider-button-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.rd-container .slider-button-container::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
  width: 5px;
}

.rd-container .slider-button-container::-webkit-scrollbar-thumb {
  background-color: rgba(39, 110, 241, 0.8);
  border-radius: 100rem;
  width: 5px;
}

.rd-container .slider-button-container .slider-button {
  border: 1px solid #e2e2e2;
  background-color: #ffffff;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  padding: 8px 16px;
  color: #545454;
  min-height: 42px;
  border-radius: 8px;
}

.rd-container .slider-button-container .slider-button.slider-button--active {
  background-color: #276ef1;
  color: #ffffff;
}

.rd-container .swiper-reksadana-slider .swiper-button-prev {
  transform: translateX(-48px);
}

.rd-container .swiper-reksadana-slider .swiper-button-prev,
.rd-container .swiper-reksadana-slider .swiper-button-next {
  display: none;
}

.rd-container .reksadana-slider .custom-navigation-button {
  display: none;
}

@media screen and (min-width: 992px) {
  .reksadana-card {
    flex-direction: row;
  }

  .rd-container .swiper-reksadana-slider {
    width: calc(100% - 48px - 48px - 32px);
  }

  .rd-container .reksadana-slider {
    position: relative;
  }

  .reksadana-slider .swiper .swiper-pagination {
    margin-top: 0;
    display: none;
  }

  .rd-container .reksadana-slider .custom-navigation-button {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    display: block;
  }

  .rd-container .reksadana-slider .custom-navigation-button {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }

  .rd-container .reksadana-slider .custom-navigation-button span {
    position: relative;
    display: block;
    width: 15px;
    height: 30px;
  }

  .rd-container .reksadana-slider .custom-navigation-button span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .rd-container .reksadana-slider .custom-navigation-button span::after,
  .rd-container .reksadana-slider .custom-navigation-button span::before {
    content: "";
    width: 18px;
    border-radius: 2px;
    height: 2px;
    background-color: #1979f5;
    position: absolute;
  }

  .rd-container .reksadana-slider .custom-navigation-button--prev {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .rd-container .reksadana-slider .custom-navigation-button--prev span::before {
    top: 8px;
    right: 6px;
    transform: rotate(135deg);
    transform-origin: center;
  }

  .rd-container .reksadana-slider .custom-navigation-button--prev span::after {
    right: 6px;
    bottom: 8px;
    transform: rotate(45deg);
    transform-origin: center;
  }

  .rd-container .reksadana-slider .custom-navigation-button--next {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .rd-container .reksadana-slider .custom-navigation-button--next span::before {
    top: 8px;
    left: 6px;
    transform: rotate(45deg);
    transform-origin: center;
  }
  .rd-container .reksadana-slider .custom-navigation-button--next span::after {
    left: 6px;
    bottom: 8px;
    transform: rotate(-45deg);
    transform-origin: center;
  }

  .reksadana-card .rd-section.image-section {
    width: 38.5%;
  }

  .reksadana-card .rd-section .img-wrapper {
    max-height: 248px;
  }
}
@media screen and (min-width: 1200px) {
}
