@import '@fortawesome/fontawesome-free/css/all.css';

.sfs-layout {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  padding: 0;
  line-height: normal;
  top: 0;
}

.sfs-layout .sfs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 150px;
  border-bottom: 1px solid #E3E7EF;
}

.button-header, .button-header:focus, .button-header:active {
  height: 36px;
  width: 166px;
  border-radius: 28px;
  padding: 0 22px;
  background: #047CFF;
  border: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Source Sans 3' !important;
}
.button-header:hover {
  background: #047CFF;
  color: #FFFFFF;
  text-indent: -5px;
}
.button-header:hover::after, .button-header:focus::after{
  text-indent: 0px;
  font: var(--fa-font-solid);
  content: "\f061";
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 0;
  left: unset !important;
  height: 100%;
  width: 40px;
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}

.site-layout {
  background-color: #FFFFFF;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media screen and (max-width: 480px) {
  .sfs-layout .sfs-header {
    padding: 0 30px;
  }
}