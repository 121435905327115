.swiper-pagination:has(.custom-line-bullet) {
  margin-top: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 6px;
}

.custom-line-bullet {
  cursor: pointer;
  background-color: #dee1e1;
  width: 18px;
  height: 6px;
  border-radius: 10px;
  transition: all 0.2s ease-out;
}

.custom-line-bullet:hover {
  background-color: #a8aaaa;
}

.custom-line-bullet.swiper-pagination-bullet-active {
  transition: all 0.2s ease-in;
  background-color: #047cff !important;
  width: 48px;
}
