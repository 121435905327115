.sfs-layout .sfs-menu {
  border-top: 1px solid #e3e7ef;
  display: none;
  overflow-y: scroll;
  height: unset;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  opacity: 0;
  transition: 0.3s;
}
.sfs-layout .sfs-menu.active {
  display: flex;
  opacity: 1;
  z-index: 999;
  box-shadow: 0px 6.650102138519287px 5.32008171081543px 0px #00000007;
  box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px #0000000b;
  box-shadow: 0px 100px 80px 0px #00000012;
}

.sfs-layout .menu .menu-mobile {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  height: 48px;
  background: #f1f4f7;
  border: 1px solid #f1f4f7;
  color: #000000;
  border-radius: 36px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0;
}

.sfs-layout .menu.sub.first {
  margin-top: 20px;
}

.sfs-layout .menu .menu-mobile.has-sub::after {
  text-indent: 0px;
  font: var(--fa-font-solid);
  content: "\f061";
  font-size: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 0;
  left: unset !important;
  height: 100%;
  width: 40px;
}

.sfs-layout .submenu .menu:not(.active):not(.sub) {
  display: none;
}

.sfs-layout .menu.active .menu-mobile.has-sub {
  text-indent: 40px;
  background-color: #111B29;
  color: #FFFFFF;
}

.sfs-layout .menu.active .menu-mobile.has-sub::after {
  content: "\f060";
  left: 15px;
  top: 0;
  right: unset !important;
}