.hamburger-menu-icon {
  width: 40px;
  position: relative;
  height: 35px;
  margin-left: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.hamburger-menu-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #373737;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.hamburger-menu-icon span:nth-child(1) {
  top: 0px;
}

.hamburger-menu-icon span:nth-child(2),.hamburger-menu-icon span:nth-child(3) {
  top: 15px;
}

.hamburger-menu-icon span:nth-child(4) {
  top: 30px;
}

.hamburger-menu-icon:hover span:nth-child(1) {
  top: 30px;
  bottom: 0;
  /* width: 0%;
  left: 50%; */
}
.hamburger-menu-icon:hover span:nth-child(2) {
  top: 30px;
  bottom: 0;
}
.hamburger-menu-icon:hover span:nth-child(3) {
  top: 30px;
  bottom: 0;
}
.hamburger-menu-icon:hover span:nth-child(4) {
  /* top: 18px;
  width: 0%;
  left: 50%; */
}

.hamburger-menu-icon.active span:nth-child(1) {
  top: 15px;
  width: 0%;
  left: 50%;
}
.hamburger-menu-icon.active span:nth-child(2) {
  top: 15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hamburger-menu-icon.active span:nth-child(3) {
  top: 15px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.hamburger-menu-icon.active span:nth-child(4) {
  top: 15px;
  width: 0%;
  left: 50%;
}