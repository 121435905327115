@import "~antd/dist/antd.css";
@import "@fortawesome/fontawesome-free/css/all.css";

@font-face {
  font-family: "Source Sans 3";
  src: url("./Assets/Fonts/Source_Sans_3/SourceSans3-VariableFont_wght.ttf")
    format("truetype");
}

#root {
  height: 100%;
}

body {
  font-family: "Source Sans 3" !important;
  letter-spacing: 0.5px;
}

.ant-typography.h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  text-align: left;
  color: #000000;
  display: block;
}

.ant-typography.h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}

.ant-typography.h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}

.ant-typography.h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34.18px;
  text-align: center;
  color: #000000;
  display: inline-block;
  letter-spacing: 0em;
}

.ant-typography.h5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #000000;
  display: inline-block;
  letter-spacing: 0em;
}

.ant-typography.body {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0em;
  display: block;
}

.btn-primary {
  height: 40px;
  /* min-width: 178px; */
  width: 100%;
  border-radius: 36px;
  padding: 10px 16px;
  background: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  border: none;
}

.btn-blue {
  height: 40px;
  border-radius: 36px;
  padding: 10px 16px;
  background: #7fbafb !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.btn-primary:hover {
  box-shadow: 4px 4px 0px #111b29;
  color: #000000;
  border: none;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary:focus {
  background: #111b29;
  color: #ffffff;
  box-shadow: none;
}

.btn-blue:hover {
  /* box-shadow: 4px 4px 0px #7FBAFB; */
  color: #ffffff;
  border: none;
}

.btn-blue.active {
  background: #ffffff !important;
  color: #000000;
  box-shadow: none;
}

[ant-click-animating-without-extra-node="true"]::after {
  opacity: 0;
}

.float-button {
  position: fixed;
  width: 166px;
  height: 36px;
  bottom: 40px;
  right: 40px;
  background: #111b29;
  color: #fff;
  font-weight: 900;
  line-height: 36px;
  font-weight: 36px;
  border-radius: 36px;
  text-align: center;
  padding: 12px, 22px, 12px, 22px;
  box-shadow: 4px 4px 34px 0px #00000040;
  z-index: 10;
}

.ant-notification {
  top: 90px !important;
}

.float-buton:hover {
  color: #fff;
  background: #047cff;
}

.card-jejak-digital {
  border-radius: 24px;
  background: #f1f4f7;

  /* Raised */
  box-shadow: 0px 8px 24px 0px rgba(148, 168, 190, 0.24),
    0px 4px 8px 0px rgba(148, 168, 190, 0.16);
}

.timeline {
  white-space: nowrap;
  overflow-x: hidden;
}
.timeline ol {
  font-size: 0;
  width: 100vw;
  padding: 50px 0 250px 0;
  transition: all 1s;
}
.timeline ol li.inactive {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 420px;
  height: 5px;
  background: #e8edf1;
  cursor: pointer;
}
.timeline ol li.active {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 420px;
  height: 5px;
  background: #276ef1;
  cursor: pointer;
}
.timeline ol li:first-child {
  width: 0;
}
.timeline ol li.active:last-child {
  width: 420px;
}
.timeline ol li.inactive:last-child {
  width: 420px;
}
.timeline ol li:not(:first-child) {
  margin-left: 25px;
}
.timeline ol li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 100%;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
  border-radius: 50%;
  border: 6px solid #276ef1;
  background: #fff;
}

.timeline ol li div.inactive {
  position: absolute;
  left: calc(100% + 7px);
  width: 280px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  top: calc(100% + 16px);
}

.timeline ol li div.inactive::before {
  position: absolute;
  top: 100%;
  left: 1em;
  width: 0;
  height: 0;
  border-style: solid;
  top: -8px;
}

.timeline ol li div.active {
  position: absolute;
  left: calc(100% + 7px);
  width: 280px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: black;
  border-radius: 16px;
  background: var(--primary-white, #fff);
  top: calc(100% + 16px);
}

.timeline ol li div.active::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 1em;
  width: 0;
  height: 0;
  border-style: solid;

  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.jejak-digital-milestone {
  border-radius: 48px;
  background: #1979f5;
  padding: 0px 8px;
  align-items: center;
  display: inline-block;
  color: #fff;

  font-family: Source Sans 3;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 200% */
  letter-spacing: -0.285px;
  margin-bottom: 8px;
}

.left-section {
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
  width: 100%;
  margin-bottom: 2rem;
}

.left-section .sub-header {
  margin-bottom: 0.5rem;
}

.left-section .filter {
  color: #757575;
}

.left-section .reset {
  color: #276ef1;
  cursor: pointer;
  text-align: right;
}

.left-section .card-filter {
  padding: 24px 0;
  border: none;
  box-shadow: 0px 12px 42px -4px #18274b1f;
  box-shadow: 0px 8px 18px -6px #18274b1f;
  border-radius: 16px;
}

.left-section .card-filter .card-container {
  padding: 0 24px;
}

.left-section .card-filter hr {
  border-top: 1px solid #cbcbcb;
  margin: 1rem 0;
}

.left-section .card-filter .ant-card-body {
  padding: 0px;
}

.left-section .card-filter .title {
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.left-section .card-filter .radio > div,
.left-section .card-filter .list > a {
  margin-bottom: 0.5rem;
  color: #757575;
  font-weight: 500;
}
.left-section .card-filter .radio > div .ant-radio-wrapper {
  color: #757575;
}

.left-section .card-filter .list > a {
  padding: 0 24px;
  display: block;
}

.left-section .card-filter .list > a.active {
  background-color: #e3ecfd;
  color: #276ef1;
  font-weight: bold;
  padding: 0.25rem 24px;
}

.right-section .sub-header {
  margin-bottom: 1.5rem;
}

.right-section .sub-header .title {
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
}

.right-section .sub-header .description {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
}

.right-section .card-item img {
  border-radius: 10px;
  object-fit: cover;
  height: 180px;
  width: 100%;
}

.right-section .card-item .title {
  margin-top: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  text-overflow: ellipsis;
}

.right-section .card-item .date {
  font-weight: 400;
  color: #000000;
  font-size: 15px;
}

.table-prospectus .ant-table {
  font-family: Source Sans 3;
  font-size: 16px;
}

.table-prospectus .ant-table-row .ant-table-cell:first-of-type {
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .float-button {
    right: 20px;
    bottom: 20px;
  }

  .timeline ol li.inactive {
    width: 150px;
  }
  .timeline ol li.active {
    width: 150px;
    height: 5px;
  }
  .timeline ol li:first-child {
    width: 0;
  }
}
