.section7 {
  padding: 80px 150px;
}

.section7 .h1 {
  max-width: 700px;
  line-height: 45px;
  display: inline-block;
  margin-bottom: 15px;
}

.section7 .h5 {
  margin-bottom: 10px;
  text-align: left;
  display: block;
}

.section7 .h5.judul {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.section7 .product {
  padding: 0;
  border: none;
}

.section7 .product > div {
  padding: 0;
}

.section7 .product img {
  border-radius: 12px;
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.section7 .product:hover img {
  box-shadow: 5px 5px 0px #000000;
}

.section7 .product .h5 {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
}

.section7 .button-header {
  height: 36px;
  width: 261px;
  background: #111B29;
}

.section7 .button-header:hover::after, .section7 .button-header:focus::after {
  right: 50px;
}


@media (max-width: 768px) {
  .section7 {
    padding: 80px 30px;
  }

  .section7 .ant-col {
    padding-bottom: 20px;
  }
}