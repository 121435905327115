.page-header {
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #E3E7EF;
  min-height: 280px;
  padding: 30px 150px;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  background: linear-gradient(267.83deg, #499AF8 0%, #48A4F7 48.47%, #7CB8FB 100%),linear-gradient(0deg, #F1F4F7, #F1F4F7);
}

.page-header:hover {
  background: linear-gradient(267.83deg, #499AF8 0%, #48A4F7 48.47%, #48A4F7 100%),linear-gradient(0deg, #F1F4F7, #F1F4F7);
}

.page-header .h1 {
  color: #FFFFFF;
  margin-top: auto;
  margin-bottom: 20px;
  z-index: 3;
}

.page-header button {
  z-index: 3;
}

.page-header .image-animation .background {
  z-index: 1;
  position: absolute;
  top: -32vw;
  right: -60vw;
  transition: .2s ease;
}
.page-header:hover .image-animation .background {
  right: -55vw;
}

.page-header .news {
  position: absolute;
  right: 10vw;
  bottom: 0;
  z-index: 2;
}


.page-header .container {
    overflow-x: auto;
}

.page-header .container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

@media (max-width: 768px) {

  .page-header {
    padding: 10px 20px;
  }

  .page-header .image-animation {
    /* height: 160px; */
  }

  .page-header .image-animation .background {
    top: -50vh;
    right: unset;
    left: 50vw;
  }

  .page-header:hover .image-animation .background {
    top: -50vh;
    right: unset;
    left: 50vw;
  }

  .page-header .news {
    position: relative;
    scale: 0.6 0.6;
    right: 20vw;
    bottom: -55px;
  }

  .page-header .h1 {
    margin-bottom: 0;
  }
}