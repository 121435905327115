.hero .ant-image {
  display: block !important;
  background-color: #000;
}
.hero .ant-image img {
  object-fit: cover;
  object-position: center; /* Center the image within the element */
  height: 600px;
  width: 100%;
  opacity: .6;
}
.hero .ant-image:hover img {
  opacity: .4;
  transition: 0.5s;
}
.hero .slick-dots {
  right: unset;
  margin-left: 150px;
  top: 200px;
}
.hero .slick-dots li {
  width: 18px;
}
.hero .slick-dots li button {
  height: 6px;
  width: 18px;
  border-radius: 8px;
  background: #DEE1E1;
  opacity: unset;
}
.hero .slick-dots li.slick-active {
  width: 48px;
}
.hero .slick-dots li.slick-active button {
  width: 48px;
  background: #047CFF;
}

.hero .hero-container {
  position: relative;
}

.hero .hero-container .hero-overlay {
  position: absolute;
  z-index: 1;
  top: 220px;
  left: 150px;
  color: #DEE1E1;
  max-width: 600px;
}

.hero .hero-container .hero-overlay .ant-typography {
  color: #FFFFFF;
  font-size: 45px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 1.5px;
  text-align: left;
}

.hero .hero-container .hero-overlay .ant-btn {
  background-color: #111B29;
  height: 36px;
  width: 261px;
  border-radius: 36px;
  padding: 0 22px;
  margin: 30px 0;
}
.hero .hero-container .hero-overlay .ant-btn:hover::after, .hero .hero-container .hero-overlay .ant-btn:focus::after {
  right: 50px;
}

.slider {
  position: relative;
  z-index: 0;
  border-bottom: 1px solid #E3E7EF;
}
.slider .buttonAction {
  position: absolute;
  z-index: 99999;
  bottom: 200px;
  left: 150px;
  color: #DEE1E1;
  max-width: 600px;
  font-size: 50px;
}
.slider .buttonAction > .fa:first-child {
  margin-right: 60px;
  font-weight: bolder;
}
.slider .buttonAction > .fa {
  cursor: pointer;
}

@media (max-width: 768px) {
  .hero .slick-dots {
    margin-left: 20px;
  }
  .hero .hero-container .hero-overlay {
    left: 20px;
  }
  .slider .buttonAction {
    left: 20px;
    bottom: 150px;
    font-size: 30px;
  }
}