.section5 {
  padding: 70px 150px;
  background: linear-gradient(180deg, #BCDFFF 0%, rgba(188, 223, 255, 0) 100%);
  text-align: center;
}

.section5 a {
  padding-top: 20px;
  display: inline-block;
}

.section5 .ant-typography.h5 {
  text-align: left !important;
  display: block;
}

.section5 .product {
  display: flex;
  columns: auto;
  height: 328px;
  border-radius: 29px;
  border: none;
  background-color: none;
  box-shadow: 4px 4px 32px 0px #0000001F;
  margin-top: 30px;
}

.section5 .product:hover {
  box-shadow: 6px 6px 0px 0px #000000;
  transition: .3s ease-in-out;
}

.section5 .product .ant-card-body {
  display: flex;
  align-items: center;
  text-align: left;
}

.section5 .product .h3 {
  margin: 20px 0;
}


@media (max-width: 768px) {
  .section5 {
    padding: 50px 30px;
  }

  .section5 .h1 { 
    text-align: left;
  }
  .section5 .h5 {
    text-align: justify;
  }
  .section5 .item-container {
    overflow-x: scroll;
    overflow-y: visible;
    flex-flow: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section5 .item-container::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  .section5 .product {
    margin-top: unset;
  }
}