.client-slider {
  padding: 0 16px;
  width: 100%;
  height: 100%;
}
.client-slider .swiper {
  display: flex;
  height: 100%;
}

.client-slider .swiper-wrapper {
  display: flex;
  height: 100%;
}

.client-slider .swiper-slide {
  height: 100%;
}

.client-image-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 100%;
}

.client-image-wrapper img {
  width: auto;
  max-width: 100%;
  object-fit: cover;
  height: auto;
  max-height: 42px;
}
