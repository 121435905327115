@import url('https://fonts.googleapis.com/css?family=Inter');

.base-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  min-height: 40px;
  padding: 8px 16px;
  border-radius: 10px;
  border: none;
  outline: 0;
  cursor: pointer;
}

.base-button-rounded {
  border-radius: 999px;
}

.base-button-outline-secondary {
  background-color: #FFFFFF;
  border: 1px solid #E2E2E2;
  color: #545454;
}

.base-button-primary {
  background-color: #276EF1;
  color: #FFFFFF;
}

.base-button-primary:hover {
  background-color: #1964f0;
  color: #FFFFFF;
}