.layout-single-page {
  padding: 30px 150px;
}

.layout-single-page.research .ant-skeleton.ant-skeleton-element {
  width: 100%;
  height: 100%;
}

.layout-single-page.research
  .ant-skeleton.ant-skeleton-element
  .ant-skeleton-image {
  width: 100%;
  border-radius: 12px;
  min-height: 180px;
  overflow: hidden;
}

.layout-single-page.research .pagination-skeleton {
  display: flex;
  gap: 16px;
  flex-wrap: nowrap;
}

.layout-single-page.research
  .pagination-skeleton
  .ant-skeleton:has(.ant-skeleton-button) {
  width: max-content;
}

.layout-single-page.research
  .pagination-skeleton
  .ant-skeleton
  .ant-skeleton-button {
  width: 32px;
  min-width: 32px;
  border-radius: 4px;
  height: 32px;
  overflow: hidden;
}

.text-align-center {
  text-align: center !important;
}

@media screen and (min-width: 769px) {
  .medium-text-align-center {
    text-align: center !important;
  }
}

.layout-single-page .img-section {
  text-align: center;
}
.layout-single-page .img-section .img-rounded {
  box-shadow: 4px 4px 32px 0px #0000001f;
  border-radius: 39px;
  width: 100% !important;
}
.layout-single-page .img-section .ant-image {
  width: 100% !important;
}

.layout-single-page .img-section .img-rounded:hover {
  box-shadow: 6px 6px 0px 0px #000000;
}

.layout-single-page .img-section {
  margin-bottom: 40px;
}

.layout-single-page .h2 {
  margin-bottom: 10px;
}

.layout-single-page .h3 {
  line-height: 36px;
  font-size: 24px;
  margin: 0;
}

.layout-single-page .h5 {
  text-align: justify;
}

.layout-single-page .divider {
  border: 1px solid #f1f4f7;
}

.layout-single-page ul {
  list-style-type: disc;
}

.layout-single-page .card {
  padding: 20px;
  box-shadow: 0px 8px 24px 0px rgba(148, 168, 190, 0.24),
    0px 4px 8px 0px rgba(148, 168, 190, 0.16);
  border: none;
  border-radius: 24px;
  min-height: 400px;
}

.layout-single-page .card .jejak-digital {
  display: flex;
  align-items: center;
  position: relative;
}

.layout-single-page .card .direction {
  margin: 0 30px;
  font-size: 30px;
  margin-top: 20px;
}

.layout-single-page .card .direction i {
  cursor: pointer;
}

.layout-single-page .card .description .h5 {
  font-weight: 600;
}

.layout-single-page .team-image {
  /* width: 356px; */
}

.layout-single-page .team-group p {
  text-align: justify;
  color: #000000;
}

.layout-single-page .team-group > .h5:first-of-type {
  display: block;
}
.layout-single-page .team-group > .h5:last-of-type {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.layout-single-page .download .apps {
  height: 264px;
  width: 261px;
  border-radius: 0px 0px 24px 24px;
  filter: drop-shadow(3px 3px 36px rgba(0, 0, 0, 0.25));
  border-radius: 29px;
  overflow: hidden;
}

.layout-single-page .download .apps .image {
  background: linear-gradient(180deg, #1979f5 0%, #48a4f7 51.04%, #bcdfff 100%);
  /* padding: 15px; */
  text-align: center;
}

.layout-single-page .download .apps .image img {
  max-width: 100%;
  height: 134px;
  object-fit: cover;
  /* width: 261px; */
}

.layout-single-page .download .apps .link {
  font-size: 16px;
  height: 100%;
  padding: 15px;
  background-color: #ffffff;
}

.layout-single-page .download .apps .link .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout-single-page .download .apps .link .title i {
  margin-right: 10px;
}

.layout-single-page .download .apps .link .title .h5 {
  font-weight: 600;
  letter-spacing: 0.5px;
}

.layout-single-page .download .apps .link > a {
  margin-top: auto;
  display: block;
  padding-top: 10px;
}

.layout-single-page .download .apps .link .title a {
  font-size: 18px;
  color: #000000;
}

.layout-single-page .download .apps .link .title a:hover {
  transform: translateY(20%);
  transition: 0.3s ease-in-out;
}

.layout-single-page .download .apps .image {
  position: relative;
  display: block;
  padding-top: 10px;
}

.layout-single-page .download .apps .image .img-1 {
  position: absolute;
  transition: 0.3s ease-in-out;
}

.layout-single-page .download .apps .image:hover .img-1 {
  transform: translateX(-200%);
}

.layout-single-page .download .apps .image .img-2 {
  transform: translateX(200%);
  transition: 0.3s ease-in-out;
}

.layout-single-page .download .apps .image:hover .img-2 {
  transform: translateX(0);
}

.layout-single-page.news .product {
  padding: 0;
  border: none;
}

.layout-single-page.news .product > div {
  padding: 0;
}

.layout-single-page.news .product img {
  border-radius: 12px;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.layout-single-page.news .product .h5.judul {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.layout-single-page.news .product:hover img {
  box-shadow: 5px 5px 0px #000000;
}

.layout-single-page.news .product .h5 {
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
}

.layout-single-page.news .pagination {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.layout-single-page.news .pagination .dot {
  display: flex;
  align-items: center;
}

.layout-single-page.news .pagination .dot i {
  margin: 0 5px;
  line-height: 20px;
  color: #bcdfff;
}

.layout-single-page.news .pagination .dot i.active {
  color: #000000;
}

.layout-single-page.news .pagination button {
  border: none;
  background-color: transparent;
}

.layout-single-page.news .pagination .prev {
  margin-right: 20px;
}

.layout-single-page.news .pagination .next {
  margin-left: 20px;
}

.layout-single-page.news-detail > img {
  width: 100%;
}

.layout-single-page.news-detail .h2 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.layout-single-page.news-detail .body.date {
  margin-bottom: 20px;
}

.layout-single-page.news-detail p {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: justify;
  letter-spacing: 0em;
  display: block;
  margin-bottom: 15px;
}

.layout-single-page.contact .top .h3 {
  text-align: center;
  margin-bottom: 20px;
}

.layout-single-page.contact .top .ant-card {
  padding: 20px;
  border-radius: 16px;
  border: none;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.12);
  background: linear-gradient(90deg, #a1c4fd 0%, #78b5fb 100%);
}

.layout-single-page.contact .top .ant-card .circle-image {
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #2c8bf6 0%, #48a4f7 49.48%, #bcdfff 100%);
  border-radius: 50%;
  width: 260px;
  height: 260px;
}

.layout-single-page.contact .top .ant-card .circle-image .background {
  position: relative;
  z-index: 1;
  transition: 0.5s ease;
}

.layout-single-page.contact .top .ant-card .circle-image .cs {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  transform: translateX(10%);
  transition: 0.5s ease;
}

.layout-single-page.contact .top .ant-card .circle-image:hover .background {
  scale: 1.1 1.1;
}

.layout-single-page.contact .top .ant-card .circle-image:hover .cs {
  scale: 1.1 1.1;
  transform: translateX(0);
}

.layout-single-page.contact .top .ant-card .form input {
  width: 100%;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  border-radius: 10px;
  gap: 5;
}

.layout-single-page.contact .top .ant-card .form input:focus {
  padding: 5px 10px;
  outline: none;
  background-color: #f7f7f7;
}

.layout-single-page.contact .top .ant-card .button-header {
  margin-left: auto;
  height: 36px;
  width: 166px;
  background: #111b29;
}

.layout-single-page.contact .top .ant-card .button-header:hover::after,
.layout-single-page.contact .top .ant-card .button-header:focus::after {
  right: 30px;
}

.layout-single-page.contact .bottom {
  margin: 30px 0;
}

.layout-single-page.contact .bottom .h3 {
  margin-bottom: 20px;
}

.layout-single-page.contact .bottom .product {
  display: flex;
  columns: auto;
  height: 180px;
  border-radius: 29px;
  border: none;
  background-color: none;
  box-shadow: 4px 4px 32px 0px #0000001f;
}

.layout-single-page.contact .bottom .product:hover {
  box-shadow: 6px 6px 0px 0px #000000;
  transition: 0.3s ease-in-out;
}

.layout-single-page.contact .bottom .product .ant-card-body {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 40px 20px 10px 20px;
}

.layout-single-page.contact .bottom .product .h3 {
  margin: 20px 0;
}

.reksa-dana {
  width: 100%;
  /* max-width: 1280px; */
  margin: 0;
}

.reksa-dana-layout {
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
}

@media screen and (min-width: 769px) {
  .reksa-dana-layout {
    gap: 40px;
  }
}

@media screen and (min-width: 992px) {
  .reksa-dana-layout {
    gap: 80px;
  }
}

.reksa-dana .reksadana-content-selector,
.content-selector {
  margin-bottom: 2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.reksa-dana .ant-space .ant-btn,
.ant-space.content-selector .ant-btn {
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  height: 40px;
  font-weight: 400;
  color: #545454;
  font-size: 16px;
  font-family: Inter, Arial, sans-serif;
}

.reksa-dana .ant-space .ant-btn.active,
.ant-space.content-selector .ant-btn.active {
  font-weight: 600;
  background-color: #276ef1;
  color: #ffffff;
}

.reksa-dana .cta-section {
  padding: 40px 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 40px;
}

.reksa-dana .cta-section .copywriting {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.cta-title {
  font-size: 48px !important;
  font-weight: 700 !important;
  line-height: 68px !important;
  margin: 0;
  max-width: 520px;
  margin-bottom: 0 !important;
}

.cta-text {
  color: #4f5e71;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 0;
  max-width: 520px;
}

.cta-text.cta-text--full-width {
  max-width: unset;
}

.reksa-dana .cta-section .image-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  min-height: 312px;
  justify-content: center;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  padding: 16px;
  background: unset;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.reksa-dana .cta-section .image-wrapper.image-wrapper--transparent {
  background: none;
}

.reksa-dana .cta-section .image-wrapper.image-wrapper--green {
  background: unset;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
}

.reksa-dana .cta-section .image-wrapper.image-wrapper--green .ant-image {
  height: 100%;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reksa-dana .cta-section .image-wrapper.image-wrapper--green img {
  height: 100%;
  margin: 0 auto;
  width: auto;
}

.reksa-dana .benefits-section {
  padding: 40px 0;
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  gap: 32px;
  justify-content: center;
}

.reksa-dana .benefits-section .typography {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  gap: 8px;
}

.reksa-dana .benefits-section .container {
  --gap: 32px;
  --col: 3;

  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: var(--gap);
  justify-content: center;
}

@media screen and (max-width: 880px) {
  .reksa-dana .benefits-section .container {
    --col: 1;
  }
}

.reksa-dana .benefits-section .benefit-card {
  flex-basis: calc(
    100% / var(--col) - var(--gap) / var(--col) * (var(--col) - 1)
  );
  width: calc(100% / var(--col) - var(--gap) / var(--col) * (var(--col) - 1));

  box-shadow: 0px 14px 50px 0px #0000000a;
  border: 1px solid #ededed;
  background-color: #ffffff;
  max-width: 360px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
  flex-flow: column nowrap;
  gap: 16px;

  border-radius: 16px;
}

.reksa-dana .benefits-section .card .ant-image {
  width: 48px;
  height: 48px;
}

.reksa-dana .reksadana-section {
  width: calc(100% + 20px + 20px);
  padding: 40px 16px calc(40px + 95px);
  gap: 80px;
  margin-left: -20px;
  margin-right: -20px;
  background-position: 50% 80%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.reksa-dana .reksadana-section .products {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  position: relative;
}

.reksa-dana .reksadana-section .typography {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}

.reksa-dana .reksadana-section .clients {
  width: 100%;
  height: 95px;
  padding: 16px 0;
  gap: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
}

.reksa-dana .data-section {
  width: calc(100% + 20px + 20px);
  height: auto;
  padding: 40px 16px;
  overflow: hidden;
  gap: 80px;
  margin-left: -20px;
  margin-right: -20px;
  background-position: 50% 55%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-flow: column nowrap;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

.reksa-dana .reksadana-section::after,
.reksa-dana .data-section::after {
  content: "";
  display: block;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.reksa-dana .reksadana-section::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.reksa-dana .reksadana-section > *,
.reksa-dana .data-section > * {
  z-index: 2;
}

.reksa-dana .reksa-dana-btn {
  color: #fff !important;
  width: 100% !important;
  max-width: 240px !important;
  padding: 12px 16px !important;
  background-color: #007aff !important;
  border: none !important;
  border-radius: 8px !important;
  height: auto !important;
  font-size: 16px;
  line-height: 1.25;
}

@media screen and (min-width: 769px) {
  .reksa-dana .reksadana-content-selector,
  .content-selector {
    margin-bottom: 40px;
    flex-direction: row;
  }

  .reksa-dana .cta-section .image-wrapper {
    min-height: 452px;
    width: 100%;
    border-radius: 12px;
    padding: 16px;
  }

  .reksa-dana .cta-section .typography {
    max-width: 524px;
  }

  .reksa-dana .reksadana-section,
  .reksa-dana .data-section {
    width: calc(100% + 150px + 150px);
    margin-left: -150px;
    margin-right: -150px;
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media screen and (min-width: 992px) {
  .reksa-dana .cta-section {
    flex-direction: row;
  }

  .reksa-dana .cta-section,
  .reksa-dana .reksadana-section,
  .reksa-dana .benefits-section,
  .reksa-dana .data-section,
  .reksa-dana .faq-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .reksa-dana .reksadana-section {
    padding-bottom: calc(80px + 95px);
  }

  .reksa-dana .cta-section .copywriting,
  .reksa-dana .cta-section .image-wrapper {
    width: calc(50% - 16px);
  }
}

.reksa-dana .pencarian {
  border: 1px solid #e2e2e2;
  border-radius: 3rem;
  margin-bottom: 2rem;
  box-shadow: 4px 4px 5px 0px #0000001f;
}

.reksa-dana .pencarian .group-pencarian {
  margin-bottom: 0;
  width: 100%;
}

.reksa-dana .pencarian .group-pencarian .group-label .label-group {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.reksa-dana
  .pencarian
  .group-pencarian
  .group-label
  .label-group
  .ant-space-item {
  width: 100%;
}

.reksa-dana .pencarian .group-pencarian .group-search {
  padding: 1.5rem 1.5rem;
}

.reksa-dana .pencarian .group-pencarian .group-label {
  padding: 1.5rem 1.5rem;
  position: relative;
}

.reksa-dana .pencarian .group-pencarian .group-label:after {
  content: "";
  height: 100%;
  width: 2px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e2e2e2;
}

.reksa-dana .pencarian .group-pencarian .group-label .reset {
  color: #276ef1;
  cursor: pointer;
}

.reksa-dana .pencarian .group-pencarian .group-label .label-group {
  color: #000000;
  display: flex;
  padding-right: 5px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
}

.reksa-dana
  .pencarian
  .group-pencarian
  .group-label
  .label-checkbox
  .ant-checkbox-group {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.reksa-dana
  .pencarian
  .group-pencarian
  .group-label
  .label-group
  .ant-btn-primary {
  color: #276ef1;
  background-color: transparent;
  text-align: right;
  margin-left: 1rem;
  height: 24px;
  padding: 0 10px;
}

.reksa-dana .pencarian .group-pencarian .group-search .ant-input-affix-wrapper {
  border-radius: 10px;
}

.reksa-dana .pencarian .group-pencarian .group-search .ant-input-prefix {
  margin-right: 10px;
}

.reksa-dana .penc {
  margin-bottom: 10px;
}

.reksa-dana .pencarian div,
.reksa-dana .pencarian span {
  font-size: 12px;
}

.reksadana-table {
  font-family: "Inter";
}

.reksadana-table.reksadana-table--rounded {
  border-radius: 15px !important;
  overflow: hidden;
}

.reksadana-table th.ant-table-cell {
  padding: 15px;
  background-color: #eeeeee;
  font-size: 14px;
  font-weight: 700;
  color: #0000009d !important;
}

.reksadana-table td.ant-table-cell {
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #000000 !important;
}

.reksadana-table table,
th {
  /* border-top-left-radius: 10px !important; */
}

.reksadana-table .ant-table.ant-table-bordered > .ant-table-container,
.reksadana-table table {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.reksadana-table th {
  border-right: 2px solid #dfdfdfb9 !important;
  border-bottom: 2px solid #dfdfdfb9 !important;
}

.reksa-dana td {
  border-right: 2px solid #dfdfdfb9 !important;
  border-bottom: 2px solid #dfdfdfb9 !important;
}

.reksadana-table th:first-child {
  border-top-left-radius: 15px !important;
}

.reksadana-table th:last-child {
  border-top-right-radius: 15px !important;
}

.maps-main {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 1rem;
  height: 400px;
}

.maps-child {
  height: 200px;
}

.table-wrapper {
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: #fff;
}

.table {
  width: 100%;
  overflow-x: auto;
}

.icon-card {
  display: flex;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 0px 8px 24px 0px #94a8be3d;
  height: 500px;
}

.icon-card .img-information {
  width: 45%;
}

.icon-card .text-information {
  width: 55%;
  padding: 30px 15px;
}

.icon-card .img-information img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.icon-card .text-information > .h4 {
  margin-bottom: 1rem;
}

.icon-card .text-information > .body {
  margin-bottom: 1rem;
}

.icon-card .text-information .tag-icon {
  background-color: #eef4fe;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.icon-card .text-information .label-group {
  display: flex;
}

.icon-card .text-information .label-group .ant-image {
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .layout-single-page {
    padding: 30px 20px;
  }

  .layout-single-page .card {
    padding-left: 16px;
    padding-right: 16px;
  }

  .layout-single-page .card .ant-card-body {
    padding: 0;
  }

  .layout-single-page .card .h2 {
    margin-bottom: 16px;
    line-height: 45px;
  }

  .layout-single-page .team-group {
    margin-bottom: 30px;
  }
  .layout-single-page .team-group > .h5:first-of-type {
    margin-top: 10px;
  }

  .reksa-dana .pencarian .group-pencarian .ant-space-item:first-child {
    min-width: 0;
  }

  .reksa-dana .pencarian .group-pencarian .group-label {
    padding-bottom: 0;
  }

  .reksa-dana
    .pencarian
    .group-pencarian
    .group-label
    .label-checkbox
    .ant-checkbox-group {
    display: inline-block;
    width: 100%;
  }

  .reksa-dana
    .pencarian
    .group-pencarian
    .group-label
    .label-checkbox
    .ant-checkbox-group-item {
    width: 100%;
  }
  .reksa-dana .pencarian .group-pencarian .group-label {
    border-right: none;
  }

  .icon-card {
    display: block;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0px 8px 24px 0px #94a8be3d;
    height: 100%;
  }

  .icon-card .img-information {
    width: 100%;
  }

  .icon-card .text-information {
    width: 100%;
    padding: 30px 15px;
  }

  .icon-card .img-information img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.custom-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom-pagination a {
  text-decoration: none;
  color: #757575;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 10px;
}

.custom-pagination a:hover {
  background-color: #f5f5f5;
}

.custom-pagination .prev,
.custom-pagination .next {
  margin: 0 4px;
}

.custom-pagination .pages {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.custom-pagination .pages li {
  padding: 0;
  height: 32px;
  min-width: 32px;
  margin: 0 4px;
}

.custom-pagination .pages li.active a {
  background-color: #1877f2;
  color: #ffffff;
  font-weight: 500;
}

.custom-pagination .pages li a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
  border-radius: 10px;
}

.custom-pagination .dots {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 8px 0;
}

@media (max-width: 768px) {
  .section-activities .types {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: -50px 0 0 0 !important;
    padding: 0 0 20px 0 !important;
  }

  .section-activities .types button {
    width: max-content;
  }

  .reksa-dana .pencarian .group-pencarian .group-label:after {
    width: 0;
  }

  .reksa-dana .pencarian .group-label:after .group-label .w-100 {
    width: 100%;
  }

  .reksa-dana .pencarian .group-pencarian {
    justify-content: start !important;
  }
}

.section-activities .types {
  margin-bottom: 46px;
}
