.ant-layout-footer {
  background-color: #e9f1ff;
  padding: 20px 150px;
  font-size: 16px;
}

.ant-layout-footer .h5 {
  font-weight: 600;
  display: block;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ant-layout-footer .subscribe {
  margin-top: 30px;
}

.ant-layout-footer .subscribe .input-group {
  margin-top: 5px;
  margin-bottom: 40px;
  width: 274px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
}

.ant-layout-footer .subscribe .input-group input {
  position: absolute;
  width: 274px;
  height: 40px;
  background: #ffffff;
  border-radius: 32px;
  border: none;
}

.ant-layout-footer .subscribe .input-group input {
  padding-left: 20px;
  padding-right: 40px;
}

.ant-layout-footer .subscribe .input-group button {
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  right: 10px;
  background-color: transparent;
  border: none;
}

.ant-layout-footer .footer-menu a {
  display: inline-block;
  color: #000;
  margin-bottom: 18px;
}

.ant-layout-footer .footer-menu a::after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #000000;
  content: "";
  display: block;
  height: 1px;
  width: 0;
}
.ant-layout-footer .footer-menu a:hover::after {
  width: 100%;
}

.ant-layout-footer .layout-single-page.line {
  padding: 30px 0;
}

.footer-partner-logo {
  /* display: flex; */
  text-align: center;
  
  
}

.ant-layout-footer .footer-row {
  display: flex;
  align-items: stretch;
}

.ant-layout-footer .social-media {
  height: 30px;
}

.ant-layout-footer .footer-container {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 768px) {
  .ant-layout-footer {
    padding: 20px 20px;
    font-size: 14px;
  }

  .ant-layout-footer .body {
    font-size: 14px;
  }

  .social-media {
    gap: 12px !important;
  }

  .ant-image-img{
    padding-left: 5px;
    padding-right: 5px;
  }
}
