@import url('https://fonts.googleapis.com/css?family=Inter');

.base-badge {
  border-radius: 999px;
  padding: 4px 12px;
  color: #FFFFFF;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  letter-spacing: normal;
  font-weight: 600;
  display: block;
  width: max-content;
}

.base-badge-danger {
  background-color: #FF6058;
}

.base-badge-warning {
  background-color: #FFB051;
}

.base-badge-success {
  background-color: #00AB6B;
}

.base-badge-purple {
  background-color: #DB759A;
}