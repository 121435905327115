.faq {
  font-family: 'Source Sans 3', sans-serif;
  letter-spacing: -0.019em;
}

.faq section {
  margin-top: 24px;
}

.layout-single-page {
  margin-top: 10px;
}

.sub-heading .h3 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 8px;
}

.sub-heading p {
  font-size: 18px;
  color: #757575;
  text-align: center;
}

.faq-category h3 {
  margin: 40px 0 0 0;
}

.faq-category h3,
.faq-subcategory h3 {
  font-size: 24px;
  font-weight: 600;
  color: #000000;
}

.faq-category .category-card p {
  font-size: 16px;
}

.faq-category .category-card {
  height: 100%;
}

.faq-contact {
  border-top: 1px solid #E3E7EF;
  padding-top: 48px;
}

.faq-contact h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000000;
  text-align: center;
}

.faq-contact p {
  font-size: 18px;
  text-align: center;
  color: #757575;
}

.faq-contact button {
  width: 100%;
  height: 72px;
  outline: 0;
  border: 0;
  border-radius: 16px;
  background-color: #FFFFFF;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
}

.faq-category .ant-card,
.faq-subcategory .ant-card {
  background-color: #F1F4F7;
  border-radius: 16px;
}

.faq-subcategory {
  padding-bottom: 24px;
}

.faq-category p {
  color: #1C293B;
  margin: 16px 0 0 0;
}

.faq-contact .ant-card {
  background: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%);
  border-radius: 16px;
}

.radio-button-wrapper {
  display: flex;
  align-items: center;
  padding: 8px 24px; 
  background-color: #FFFFFF;
  box-shadow: 0px 1px 4px 0px #94A8BE1F;
  min-height: 50;
  max-width: fit-content;
  border-radius: 9px;
  cursor: pointer;
  margin-top: 24px;
}

.site-collapse-custom-collapse {
  background-color: #F1F4F7;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  border-radius: 16px !important;
  background: #FFFFFF;
  border: 0px;
  padding: 4px 8px 0 8px;
}

.site-collapse-custom-collapse .site-collapse-custom-panel .ant-collapse-header-text {
  font-weight: 600;
  font-size: 24px;
  padding-right: 16px;
}

.site-collapse-custom-collapse .site-collapse-custom-panel.ant-collapse-item-active .ant-collapse-header-text {
  color: #276EF1;
}

.site-collapse-custom-collapse .site-collapse-custom-panel.ant-collapse-item-active .ant-collapse-content-box {
  color: #4F5E71;
  font-size: 16px;
}